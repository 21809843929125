import withApollo from 'next-with-apollo';
import { createUploadLink } from 'apollo-upload-client';
import {
  from,
  ApolloProvider,
  Observable,
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  FieldMergeFunction,
  FieldFunctionOptions,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import cookie from 'cookie';
import * as Sentry from '@sentry/nextjs';
// Types
import {
  GetStores_stores,
  GetStoresVariables,
} from 'api/store/types/GetStores';
// Helpers
import { getIfHaveTokenError, handleAuthError } from 'helpers/auth';
import { getTokenFromCookies, getGuestTokenFromCookies } from 'helpers/cookies';
import { isProduction } from 'helpers/env';
import { debounce } from 'helpers/debounce';
import { showToast } from 'components/common/Toast/Toast';
import type { IncomingMessage, ServerResponse } from 'http';

const debouncePageRefreshToast = debounce(() => {
  showToast({
    message: 'Please refresh the page',
    type: 'warning',
    onClick: () => {
      window.location.reload();
    },
    autoClose: false,
  });
});

// using "GetStores_stores" & "GetStoresVariables" types as an example of a regular paginated query
const merge: FieldMergeFunction<
  GetStores_stores,
  GetStores_stores,
  FieldFunctionOptions<Pick<GetStoresVariables, 'input'>>
> = (existing, incoming, { args }) => {
  const existingEntities = existing?.entities || [];
  const incomingEntities = incoming?.entities || [];
  const result = [...existingEntities, ...incomingEntities];

  if (args && !args.input?.offset) {
    // Initial fetch or refetch
    return incoming;
  }

  // Pagination
  return {
    ...incoming,
    entities: result,
  };
};

export const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    const operationsToSkipAuthorizationError = [
      'SignUpBasic',
      'SignUpGuestV3',
      'SignInV3',
      'GoogleAuth',
      'FbAuth',
      'TwitterAuth',
      'TiktokV2Auth',
      'AppleAuth',
      'SingOut',
    ];

    if (graphQLErrors) {
      const errorMessages = graphQLErrors.map(({ message }) => message);

      // Run only in browser
      if (
        typeof window !== 'undefined' &&
        errorMessages.length &&
        !operationsToSkipAuthorizationError.includes(operation.operationName)
      ) {
        return handleAuthError({ operation, forward, errorMessages });
      }

      graphQLErrors.map(({ message, path, extensions }) => {
        const isTokenError = getIfHaveTokenError(message);

        if (!isTokenError) {
          const stacktrace = extensions?.exception?.stacktrace?.join(',');
          const errorMessage = `[GraphQL error]: Message: ${message}, Code: ${extensions?.code}, Path: ${path}, Stacktrace: ${stacktrace}`;

          console.log(`[GraphQL error]: ${message}`);
          // exclude BAD_USER_INPUT errors from Sentry to prevent spamming
          if (!errorMessage.includes('BAD_USER_INPUT')) {
            Sentry.captureMessage(errorMessage, {
              tags: {
                section: 'graphql',
              },
            });
          }
        }
      });
    }

    if (networkError) {
      const errorMessage =
        typeof networkError === 'string'
          ? networkError
          : JSON.stringify(networkError);
      const logMessage = `[Network error]: ${errorMessage}. Operation name: ${
        operation.operationName
      }. Operation variables: ${
        [
          ...operationsToSkipAuthorizationError,
          'ChangePassword',
          'ResetPassword',
        ].includes(operation.operationName)
          ? `{[Filtered]}`
          : JSON.stringify(operation.variables)
      }`;

      // Show notification in case of loss of connection
      if (errorMessage.match(/Subscription timeout|Connection closed/i)) {
        debouncePageRefreshToast();
      }

      console.log(logMessage);
      Sentry.captureException(logMessage, {
        extra: { networkError },
        tags: {
          section: 'network',
        },
      });
    }
  }
);

const authLink = new ApolloLink((operation, forward) => {
  const ctx = operation.getContext() as { res?: ServerResponse };
  const localStorageToken = getTokenFromCookies();
  const localStorageGuestToken = getGuestTokenFromCookies();

  /**
   * Prevent operation if there is no token to avoid expected error on "logout"
   * as we use "resetStore" to clear apollo's cache
   *
   * Expected queries: Me(useGetCurrUser), GetMerchCartItemsList(useGetMerchCartItemList)
   */
  if (
    !localStorageToken?.accessToken &&
    !localStorageGuestToken?.accessToken &&
    (operation.operationName === 'Me' ||
      operation.operationName === 'MeProfileSetup' ||
      operation.operationName === 'GetMerchCartItemsList')
  ) {
    return new Observable((subscriber) => {
      subscriber.complete();
    });
  }

  operation.setContext(({ headers: operationHeaders = {} }) => {
    return {
      headers: {
        ...operationHeaders,
        ...(localStorageToken?.accessToken
          ? { Authorization: `Bearer ${localStorageToken.accessToken}` }
          : {}),
        ...(localStorageGuestToken?.accessToken
          ? {
              Authorization: `Bearer ${localStorageGuestToken.accessToken}`,
            }
          : {}),
      },
    };
  });

  return forward(operation).map((response) => {
    // redirect to original slug if backend send a redirect slug value
    if (typeof window === 'undefined') {
      const context = operation.getContext();
      const {
        response: { headers },
      } = context;
      const redirectUrl = headers.get('redirectslug');
      const errors = response?.errors;

      if (errors && redirectUrl) {
        ctx?.res?.writeHead(301, { Location: redirectUrl }).end();

        // return empty object to avoid graphql error
        return {};
      }
    }

    return response;
  });
});

const customHeadersLink = new ApolloLink((operation, forward) => {
  operation.setContext(
    ({
      headers = {},
      req,
    }: {
      headers: Record<string, string>;
      req?: IncomingMessage;
    }) => {
      const customHeaders = {};

      // Add XAppPlatform and XAppVersion headers
      customHeaders['x-app-platform'] = 'web';
      customHeaders['x-app-version'] = '0.1.0';

      // Pass client headers when doing ssr
      if (typeof window === 'undefined') {
        const userAgent = headers?.['user-agent'];
        const xClientIp =
          headers?.['x-forwarded-for'] || req?.socket.remoteAddress;

        userAgent && (customHeaders['user-agent'] = userAgent);
        xClientIp && (customHeaders['x-client-ip'] = xClientIp);
      }

      // Set custom header for FB Conversion Test Code in non-prod env
      if (!isProduction) {
        const parsedCookies =
          typeof window === 'undefined'
            ? cookie.parse(headers?.cookie || '')
            : cookie.parse(document?.cookie || '');
        parsedCookies['fb_cn_test_code'] &&
          (customHeaders['x-millions-fb-cn-test-code'] =
            parsedCookies['fb_cn_test_code']);
      }

      return {
        headers: {
          ...customHeaders,
          ...headers,
        },
      };
    }
  );

  return forward(operation);
});

const httpUploadLink = createUploadLink({
  uri: () => {
    // Reach out to k8s-internal API link in SSR
    // in order not to hit AWS ALB via https and save ~70ms
    if (typeof window === 'undefined') {
      return (process.env.NEXT_PUBLIC_API_HOST_FOR_SSR ||
        process.env.NEXT_PUBLIC_API_HOST) as string;
    }

    return process.env.NEXT_PUBLIC_API_HOST as string;
  },
});

const link = from([
  errorLink,
  customHeadersLink,
  authLink,
  (httpUploadLink as unknown) as ApolloLink,
]);

export default withApollo(
  ({ initialState, ctx }) => {
    const asPath = ctx?.asPath;
    const pathname = asPath && asPath.split('?')[0];

    // check if capital letter exists and redirect to lowercase url
    if (pathname && /[A-Z]/.test(pathname)) {
      const lowerCasePathname = pathname.toLocaleLowerCase();
      ctx.res?.writeHead(301, { Location: lowerCasePathname }).end();
    }

    return new ApolloClient({
      ssrMode: typeof window === 'undefined',
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
          nextFetchPolicy: 'cache-first',
        },
      },
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              stores: {
                keyArgs: [
                  'storeRoles',
                  'input',
                  [
                    'id',
                    'slug',
                    'limit',
                    'sportId',
                    'sports',
                    'status',
                    'orderBy',
                    'direction',
                    'hasActiveAma',
                    'hasActiveMerch',
                    'hasActiveStreams',
                    'hasActiveExperience',
                    'hasArticle',
                    'email',
                    'hashtagIds',
                    'phoneNumber',
                    'socialMediaLink',
                    'storeIds',
                    'storeName',
                  ],
                ],
                merge,
              },
              amas: {
                keyArgs: [
                  'input',
                  [
                    'id',
                    'storeId',
                    'limit',
                    'orderBy',
                    'direction',
                    'status',
                    'storeIds',
                    'storeName',
                    'daysToResponse',
                    'price',
                    'sports',
                    'hashtagIds',
                    'searchTerm',
                  ],
                ],
                merge,
              },
              getMerchProducts: {
                keyArgs: [
                  'input',
                  [
                    'storeId',
                    'storeSlug',
                    'limit',
                    'orderBy',
                    'direction',
                    'size',
                    'gender',
                    'type',
                    'color',
                    'storeName',
                    'price',
                    'storeIds',
                    'sports',
                    'title',
                    'searchTerm',
                  ],
                ],
                merge,
              },
              getMemorabilia: {
                keyArgs: [
                  'input',
                  [
                    'description',
                    'direction',
                    'fulfillment',
                    'id',
                    'isOrphanPage',
                    'limit',
                    'numberOfUnits',
                    'orderBy',
                    'price',
                    'productTypes',
                    'requestPrice',
                    'searchTerm',
                    'sports',
                    'statuses',
                    'storeIds',
                    'storeStatuses',
                    'title',
                  ],
                ],
                merge,
              },
              streams: {
                keyArgs: [
                  'input',
                  [
                    'id',
                    'slug',
                    'streamStatus',
                    'storeStatus',
                    'storeId',
                    'price',
                    'scheduleDate',
                    'searchTerm',
                    'storeIds',
                    'storeName',
                    'limit',
                    'orderBy',
                    'direction',
                    'tzcodes',
                    'streamRecords',
                  ],
                ],
                merge,
              },
              streamsV2: {
                keyArgs: [
                  'input',
                  [
                    'id',
                    'slug',
                    'streamStatus',
                    'storeStatus',
                    'storeId',
                    'price',
                    'scheduleDate',
                    'searchTerm',
                    'storeIds',
                    'storeName',
                    'limit',
                    'orderBy',
                    'direction',
                    'tzcodes',
                    'priceCategories',
                    'storeSlug',
                    'streamRecords',
                  ],
                ],
                merge,
              },
              stream: {
                keyArgs: ['input', ['slug', 'storeSlug']],
                merge,
              },
              getExperiences: {
                keyArgs: [
                  'input',
                  [
                    'description',
                    'direction',
                    'id',
                    'isOrphanPage',
                    'limit',
                    'orderBy',
                    'price',
                    'searchTerm',
                    'sports',
                    'statuses',
                    'storeIds',
                    'storeName',
                    'storeStatuses',
                    'title',
                  ],
                ],
                merge,
              },
              getMediaPosts: {
                keyArgs: [
                  'input',
                  ['body', 'title', 'id', 'limit', 'searchTerm'],
                ],
                merge,
              },
              getMediaPost: {
                keyArgs: ['input', ['slug', 'storeSlug']],
                merge,
              },
              getArticleBySlug: {
                keyArgs: ['input', ['articleSlug', 'storeSlug']],
                merge,
              },
              getArticleForStore: {
                keyArgs: [
                  'input',
                  [
                    'direction',
                    'limit',
                    'offset',
                    'orderBy',
                    'statuses',
                    'storeId',
                    'storeSlug',
                  ],
                ],
                merge,
              },
              storesByHashtag: {
                keyArgs: [
                  'input',
                  [
                    'hashtagName',
                    'storeRoles',
                    'limit',
                    'status',
                    'orderBy',
                    'direction',
                    'searchTerm',
                  ],
                ],
                merge,
              },
            },
          },
        },
      }).restore(initialState || {}),
      link,
    });
  },
  {
    render: function renderWithApollo({ Page, props }) {
      return (
        <ApolloProvider client={props.apollo}>
          <Page {...props} />
        </ApolloProvider>
      );
    },
  }
);
