// Search/buy merch pages
export const MERCH = '/merch';
export const AMAS = '/personal-videos';
export const EXPERIENCES = '/experiences';
export const PRODUCTS = '/products';
export const STREAMS = '/streams';
export const ORDER_MERCH = '/order-merch';
export const ORDER_AMA = '/order-personal-videos';
// Athlete/gym products management
export const DASHBOARD = '/dashboard';
export const PROFILE = '/profile';
export const MY_SALES = '/my-sales';
export const MANAGE_MERCH = '/manage-merch';
export const MANAGE_MERCH_CREATE_MERCH_DESIGN_REQUEST = `${MANAGE_MERCH}/create-logo-request`;
export const MERCH_REQUEST_SUCCESS_PAGE = `${MANAGE_MERCH}/merch-request-success`;
export const AMA_REQUEST = '/personal-videos-request';
export const MANAGE_AMA = '/manage-brand-deals';
export const ACCOUNT_PRODUCTS = '/account-products';
export const AUTO_MERCH = '/auto-merch';
export const MANAGE_STREAMS = '/manage-streams';
export const MANAGE_EXPERIENCES = '/manage-experiences';
export const MEDIA = '/media/';
export const MEDIA_LIBRARY = '/media-library';
export const MEDIA_POSTS_LIBRARY = '/media-posts-library';
export const SOCIAL_MEDIA_POST = '/social-media-post/';
export const CHANNEL = '/channel';
export const SCHEDULE_STREAM = '/schedule-stream';
export const MEMORABILIA = '/memorabilia';
export const PURCHASES = '/orders';
export const ADDRESSES = '/addresses';
// Utility
export const HOME = '/';
export const ABOUT_US = '/about-us';
export const FIND_YOUR_ORDER = '/find-your-order';
export const ATHLETES = '/athletes';
export const BOXING = '/boxing';
export const MMA = '/mma';
export const STREAM_CHECKOUT = '/checkout/stream';
export const CHECKOUT_INFORMATION = '/checkout/information';
export const CHECKOUT_SHIPPING = '/checkout/shipping';
export const CHECKOUT_PAYMENT = '/checkout/payment';
export const CHECKOUT_CONFIRMATION = '/checkout/confirmation';
export const PRIVACY_POLICY = '/privacy-policy';
export const TERMS_OF_SERVICE = '/terms-of-service';
export const THANK_YOU = '/thank-you';
export const THANK_YOU_VIDEO = '/thank-you-video';
export const GYMS = '/gyms';
export const ORGANIZATIONS = '/organizations';
export const TAG = '/tag';
export const SPORTS = '/sports';
export const PROFILE_SETUP = '/profile-setup';
export const ACCOUNT_SETTINGS = '/account-settings';
